<template>
  <b-row>
    <b-col cols="12" md="6" lg="9">
      <b-card>
        <template>
          <div
            class="d-flex align-items-center text-center justify-content-center"
          >
            <div>
              <h4>الاشتراكات</h4>
              <unicon
                class="my-1"
                name="ticket"
                fill="#5E5873"
                width="100"
                height="30"
              ></unicon>
              <h4>
                <strong>({{ sessionForm.subscriptionsCount }})</strong>
              </h4>
            </div>
            <div class="border-right border-left">
              <h4>الدورس</h4>
              <unicon
                class="my-1"
                name="bookmark"
                fill="#5E5873"
                width="100"
                height="30"
              ></unicon>
              <h4>
                <strong>({{ sessionForm.lessonsCount }})</strong>
              </h4>
            </div>
            <div>
              <h4>الفيديوهات</h4>
              <unicon
                class="my-1"
                name="video"
                fill="#5E5873"
                width="100"
                height="30"
              ></unicon>
              <h4>
                <strong>({{ sessionForm.videosCount }})</strong>
              </h4>
            </div>
          </div>
          <div class="text-center border rounded mt-1 p-1">
            <div class="float-left my-1">
              <h3>الدروس</h3>
            </div>
            <div class="float-right">
              <b-button
                @click="submitFillUnitId()"
                to="/lessons/1"
                variant="primary"
                size="sm"
                class="my-1 d-flex align-items-center text-nowrap"
              >
                درس جديد
                <unicon
                  width="18"
                  height="20"
                  name="plus"
                  class="pl-1"
                  fill="#fff"
                ></unicon>
              </b-button>
            </div>
            <b-table
              responsive
              :items="sessionLessonsActivePage"
              :fields="fields"
              show-empty
            >
              <template #empty>
                <h4>لا يوجد دروس لهذه الجلسة</h4>
              </template>
              <template #cell(تفاصيل)="data">
                <b-button
                  :to="`/lessons/${data.item.id}/0`"
                  size="sm"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  style="padding: 2px 6px !important"
                >
                  <unicon name="arrow-left" fill="#7367f0" width="25"></unicon>
                </b-button>
              </template>
            </b-table>
            <b-col
              v-if="sessionForm.unitLessons.length > 0"
              cols="12"
              class="d-flex justify-content-center"
            >
              <b-pagination
                :value="sessionLessonsFirstPage"
                :total-rows="sessionForm.unitLessons.length"
                :per-page="sessionLessonPageLength"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => sessionLessonsPagination(value)"
              >
                <template #prev-text>
                  <unicon width="20" name="angle-right" fill="royalblue" />
                </template>
                <template #next-text>
                  <unicon width="20" name="angle-right" fill="royalblue" />
                </template>
              </b-pagination>
            </b-col>
          </div>
        </template>
      </b-card>
    </b-col>
    <b-col cols="12" md="6" lg="3">
      <b-card no-body v-if="isRoled()">
        <b-card-body>
          <validation-observer ref="updateSession">
            <b-form>
              <b-form-group label="اسم الجلسة" label-for="sessionName">
                <validation-provider
                  #default="{ errors }"
                  name="اسم الجلسة"
                  rules="required"
                >
                  <b-form-input
                    id="sessionName"
                    v-model="sessionForm.name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="تابع للمادة" label-for="subjectId">
                <validation-provider
                  #default="{ errors }"
                  name="تابع للمادة"
                  rules="required"
                >
                  <v-select
                    id="subjectId"
                    v-model="sessionForm.subjectId"
                    label="name"
                    :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                    :options="sessionSubjectsList"
                    :reduce="(item) => item.id"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="الأستاذ" label-for="teacherId">
                <validation-provider
                  #default="{ errors }"
                  name="الأستاذ"
                  rules="required"
                >
                  <v-select
                    id="teacherId"
                    v-model="sessionForm.teacherId"
                    label="name"
                    :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                    :options="sessionTeachersList"
                    :reduce="(item) => item.id"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="الترتيب" label-for="sessionOrder">
                <validation-provider
                  #default="{ errors }"
                  name="الترتيب"
                  rules="required|positive"
                >
                  <b-input-group append="#">
                    <b-form-input
                      id="sessionOrder"
                      v-model="sessionForm.unitOrder"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="السعر الافتراضي" label-for="price">
                <validation-provider
                  #default="{ errors }"
                  name="السعر الافتراضي"
                  rules="required"
                >
                  <b-input-group append="ل.س">
                    <b-form-input
                      id="price"
                      v-model="sessionForm.price"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="الوصف" label-for="description">
                <validation-provider
                  #default="{ errors }"
                  name="الوصف"
                  rules="required"
                >
                  <b-form-textarea
                    id="description"
                    v-model="sessionForm.description"
                    rows="4"
                    :state="errors.length > 5 ? false : null"
                  >
                  </b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="isFree"
                  v-model="sessionForm.isFree"
                  class="custom-control-primary mr-auto"
                  name="check-button"
                  switch
                >
                  مجانية
                </b-form-checkbox>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-card-body>
        <b-card-footer>
          <b-button
            size="md"
            type="submit"
            variant="primary"
            block
            class="mr-1"
            @click.prevent="SubmitUpdateSession()"
          >
            تعديل
          </b-button>
          <b-button-group class="w-100 pt-1">
            <b-button
              class="mr-1"
              to="/exam-sessions"
              size="md"
              variant="outline-primary"
            >
              تراجع
            </b-button>
            <b-button
              @click.prevent="SubmitRemoveSession()"
              size="md"
              class="ml-auto"
              variant="danger"
            >
              حذف
            </b-button>
          </b-button-group>
        </b-card-footer>
      </b-card>
      <b-card no-body v-else>
        <b-card-body>
            <b-form>
              <b-form-group label="اسم الجلسة" label-for="sessionName">
                  <b-form-input
                    id="sessionName"
                    v-model="sessionForm.name"
                    disabled
                  />
              </b-form-group>
              <b-form-group label="تابع للمادة" label-for="subjectName">
                <b-form-input
                    id="subjectName"
                    :value="getSubjectName(sessionForm.subjectId)"
                    disabled
                  /> 
              </b-form-group>
              <b-form-group label="الترتيب" label-for="unitOrder">
                  <b-input-group append="#">
                    <b-form-input
                      id="unitOrder"
                      v-model="sessionForm.unitOrder"
                      type="number"
                      disabled
                    />
                  </b-input-group>
              </b-form-group>
              <b-form-group label="السعر الافتراضي" label-for="price">
                  <b-input-group append="ل.س">
                    <b-form-input
                      id="price"
                      v-model="sessionForm.price"
                      disabled
                      type="number"
                    />
                  </b-input-group>
              </b-form-group>
              <b-form-group label="الوصف" label-for="description">
                  <b-form-textarea
                    id="description"
                    v-model="sessionForm.description"
                    rows="4"
                    disabled
                  >
                  </b-form-textarea>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="isFree"
                  v-model="sessionForm.isFree"
                  class="custom-control-primary mr-auto"
                  name="check-button"
                  switch
                  disabled
                >
                  مجاني
                </b-form-checkbox>
              </b-form-group>
            </b-form>
        </b-card-body>
        <b-card-footer class="d-flex">
          <b-button
            class="mr-1"
            to="/exam-sessions"
            size="md"
            variant="outline-primary"
          >
            تراجع
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<style scoped>
.card {
  min-height: 67vh;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, positive } from "@validations";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import { getUserData } from "@/auth/utils";
import { Admin } from "@/router";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BInputGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardFooter,
  BTable,
  BPagination,
  BButtonGroup,
} from "bootstrap-vue";
export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BRow,
    BCol,
    BForm,
    BCard,
    BCardBody,
    BCardFooter,
    BTable,
    BPagination,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BButtonGroup,
  },
  data: () => ({
    required,
    positive,
    fields: [
      {
        label: "اسم الدرس",
        key: "name",
      },
      { label: "عدد الفيديوهات", key: "videosCount" },
      { label: "عدد الاشتراكات", key: "subscriptionsCount" },
      "تفاصيل",
    ],
  }),
  props: {
    id: String,
  },
  mounted() {
    localize("ar");
    this.fetchSessionDetails(this.id);
  },
  computed: {
    ...mapGetters([
      "sessionForm",
      "sessionSubjectsList",
      "sessionTeachersList",
      "sessionLessonsActivePage",
      "sessionLessonsFirstPage",
      "sessionLessonPageLength",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchSessionDetails",
      "setExamSession",
      "removeUnits",
      "sessionLessonsPagination",
      "fillUnitId"
    ]),
    SubmitUpdateSession() {
      this.$refs.updateSession.validate().then((success) => {
        if (success) {
          this.setExamSession(this.sessionForm);
          this.$bvToast.toast(
            `تم تعديل الجلسة ${this.sessionForm.name} بنجاح`,
            {
              title: "تم التعديل",
              variant: "success",
              toaster: "b-toaster-bottom-left",
              solid: true,
              autoHideDelay: 2000,
              appendToast: true,
            }
          );
        }
      });
    },
    SubmitRemoveSession() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text: "هل فعلاً تريد حذف الجلسة؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            this.removeUnits([this.sessionForm.id]);
            this.$router.push({ name: "exam sessions" });
          }
        });
    },
    isRoled(){
      return getUserData().role == Admin
    },
    getSubjectName(id){
      var index = this.sessionSubjectsList.findIndex(item => item.id == id);
      if(index == -1){
        return '';
      }
      return this.sessionSubjectsList[index].name;
    },
    submitFillUnitId(){
      this.fillUnitId(+this.id)
    }
  },
};
</script>
